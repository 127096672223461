<template>
    <div class="blog" data-view>
        <div class="container">
            <div class="title">
                <p><img loading="lazy" alt="lupa" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa-executive2.png">BLOG & <span>PODCASTS</span></p>
            </div>
            <div class="col-12 col-md-8 allcontents" v-if="blogs">
                <router-link 
                    v-if="blogs[0]"
                    :to="{name: 'blogpost', params: { id: blogs[0].id}}"
                    class="routerLink"
                >
                    <div  class="blogPost" style="margin-bottom: 30px;" v-if="blogs != ''">
                        <img alt="image" loading="lazy" style="width: 100%; height: auto" :src="baseUrl + blogs[0].image"/>
                        <h2 class="headertext" style="margin-top: 10px;">{{ blogs[0].title }}</h2>
                        <p class="contentp" v-html="short_description(blogs[0].content)"></p>
                        <div>
                            <img alt="miro" loading="lazy" class="author" src="https://miro.medium.com/fit/c/80/80/1*5rYPlutRlZk8Lcs_dqr4sA.png"/>
                            <div class="authorinfo">
                                <p>{{ blogs[0].users.name }}</p>
                                <p class="date">{{ blogs[0].created_at }}</p>
                            </div>
                            <span class="readmore">Read more...</span>                        
                        </div>
                    </div>
                </router-link>
                
                <div v-if="blogs[1]" style=" text-align: left;">
                    <span style="color: rgba(0, 0, 0, 0.84); font-size: 14px;">LATEST</span>
                    <hr id="latesthr">
                </div>

                <router-link 
                    v-for="(blog,index) in blogs" 
                    :key="blog.id" 
                    v-if="index != 0" 
                    :to="{name: 'blogpost', params: { id: blog.id}}" 
                    class="routerLink"
                    
                >
                    <b-row class="blogPost" >
                        <div class="col-12 col-md-8 content">
                            <h5 class="headertext">{{ blog.title }}</h5>
                            <p class="contentp" v-html="short_description(blog.content)"></p>
                            <div class="authorinfo">
                                <p class="name">{{ blog.users.name }}</p>
                                <p class="date">{{ blog.created_at }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 imgbar" v-bind:style="{ backgroundImage: 'url(' + baseUrl + blog.image + ')' }">
                        </div>
                        <hr style="width: 100%; order: 3">
                    </b-row>
                </router-link>
            </div>
            <div class="col-12 col-md-8 content" v-else>
                <b-alert show variant="warning">No blogs added yet!</b-alert>
            </div>
            <div class="col-12 col-md-3 sidebar">
                <div v-if="$auth.check()" class="topics">
                    <h5>ADMINISTRATION</h5>
                    <hr>
                    <p @click="addNewBlog">NEW BLOG</p>
                    <p @click="manageBlogs">MANAGE BLOGS</p>
                </div>
                <div class="topics">
                    <h5>RELATED TOPICS</h5>
                    <hr>
                    <p>MENTAL HEALTH</p>
                    <p>PSYCHOLOGY</p>
                    <p>ADDICTION</p>
                    <p>NEUROSCIENCE</p>
                </div>
                <div class="popular">
                    <h5>POPULAR POSTS</h5>
                    <hr>
                    <div class="sidePost">
                        <div class="col-8 sidebar">
                            <p class="title">Grease the Groove- The Russian Military Secret to Strength-Endurance</p>
                            <p class="date">25 Mar, 2019</p>
                        </div>
                        <div class="col-4 sidebar">
                            <img loading="lazy" alt="miro" src="https://miro.medium.com/max/110/0*Bv-fKGRjBZBa1SAW"/>
                        </div>
                    </div>
                    <div class="sidePost">
                        <div class="col-8 sidebar">
                            <p class="title">The Calorie Myth</p>
                            <p class="date">25 Mar, 2019</p>
                        </div>
                        <div class="col-4 sidebar">
                            <img loading="lazy" alt="miro" src="https://miro.medium.com/max/110/1*9GadtCO3ebDEV7eOYv19VQ.jpeg"/>
                        </div>
                    </div>
                    <div class="sidePost">
                        <div class="col-8 sidebar">
                            <p class="title">Meet the ‘Hillbilly Lawyer’ Fighting to Take Down Big Pharma</p>
                            <p class="date">25 Mar, 2019</p>
                        </div>
                        <div class="col-4 sidebar">
                            <img loading="lazy" alt="miro" src="https://miro.medium.com/max/110/1*o122G4wAqdlfG8B70Kuh3w.jpeg"/>
                        </div>
                    </div>
                    <div class="sidePost">
                        <div class="col-8 sidebar">
                            <p class="title">On Being a Fat Person With Anorexia</p>
                            <p class="date">25 Mar, 2019</p>
                        </div>
                        <div class="col-4 sidebar">
                            <img loading="lazy" alt="miro" src="https://miro.medium.com/max/110/1*3GDVEkRiQNwIJ22rCzauLQ.jpeg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-row class="clearfix" style="height: 50px;"></b-row>
    </div> 
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Blogs',
    metaInfo: {
        title: 'Blogs & Podcasts',
        meta: [
            // OpenGraph
            { vmid: "og:title", property: "og:title",  content: 'Blogs & Podcasts - Club de Ejecutivos' },
            { vmid: "og:type", property: 'og:type', content: 'website' },
            // Google / Schema.org markup:
            { itemprop: 'name', content: 'Blogs & Podcasts - Club de Ejecutivos' },
        ]
    },
    data() {
        return {
            baseUrl: '',
        }
    },
    computed: mapState({
        blogs: state => state.blogs.all,
        
    }),
    methods: {
        short_description(content) {
            const regex = /<(\p)>(.*?)<\/\1>/;
            const matches = regex.exec(content);
            if(matches) {
                let l = matches[0].length;
                if(l < 150) {
                    let reg = '/^(.{' + l + '}[^\s]*).*/'
                    return matches[0].replace(reg, "$1").replace('</p>', '') + '...' + '</p>';
                } else{
                    return matches[0].replace(/^(.{150}[^\s]*).*/, "$1").replace('</p>', '') + '...' + '</p>';
                }
            } else {
                return '';
            }
        },
        addNewBlog() {
            this.$router.push({ name: 'newblog' });
        },
        manageBlogs() {
            this.$router.push({ name: 'adminblog' });
        }
    },
    async mounted() {
        process.env.NODE_ENV == 'development' ? 
            this.baseUrl =  'https://clubejecutivo.aicad.es/blogimages/' : 
            this.baseUrl = '/images/blogimages/'

        await this.$store.dispatch('blogs/getAllBlogs');
    }
}
</script>

<style scoped>
    .rowas {
        width: 100%;
    }

    div.title p {
        margin: 0;
    }

    .topBanner {
        background: #aaa;
        margin-right: 0px;
        margin-left: 0px;
    }

      .topImg {
        margin-left: auto;
        margin-right: auto;
    }

    .content, .sidebar {
        display: inline-block;
        text-align: left;
    }

    .content {
        vertical-align: middle;
        display: flex;
        flex-direction: column;
    }

    .sidebar {
        vertical-align: top;
    }

    .author {
        border-radius: 50%;
        width: 55px;
    }

    .authorinfo {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }

    .authorinfo p {
        margin-bottom: 0px;
        color: rgba(0, 0, 0, 0.84);
        font-size: 15px;
    }

    .readmore {
        float: right;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;
    }
    .contentp {
        /* margin-bottom: 0px; */
        color: rgba(0, 0, 0, 0.54);
        flex-grow: 1;
    }
    .smallerImg {
        width: 100%;
        margin-bottom: 25px;
    }

    .headertext {
        color: rgba(0, 0, 0, 0.84);
    }

    .authorinfo .date {
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
    }
    .imgbar {
        display: inline-block;
        height: 170px;
        background-position: center;
        background-size: cover;
        vertical-align: middle;
        order: 2;
        margin-bottom: 10px;
    }
    #latesthr {
        margin-top: 0px;
        margin-right: 10%;
    }
    .blogPost {
        cursor:pointer;
        transition: 0.3s ease;
        width: 90%;
        text-align: left;
    }
    .blogPost:hover {
        transition: 0.3s ease;
        opacity: 0.9;
    }
    .sidebar p {
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 14px;
        cursor: pointer;
    }
    .sidebar .topics {
        margin-bottom: 3rem;
    }
    .sidebar h5 {
        font-size: 16px;
        font-weight: 600;
    }

    .popular .sidebar .title {
        color: rgba(0, 0, 0, 0.84);
    }

    .sidePost {
        margin-bottom: 25px;
        cursor: pointer;
        transition: 0.3s ease;
    }

    .sidePost:hover {
        opacity: 0.9;
        transition: 0.3s ease;
    }

    .sidePost .sidebar {
        padding: 0px;
        padding-right: 10px;
    }

    .sidebar .date {
        font-size: 12px;
    }

    @media screen and (max-width: 767px) {
        .blogPost {
            width: 100%;
        }

        .authorinfo .date {
            float: right;
        }

        .authorinfo .name {
            float: left;
        }
    }

    .allcontents {
        display: inline-block;
    }

    p {
        margin-bottom: 0px;
    }

  

</style>

    